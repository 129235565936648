.tw-absolute {
  position: absolute
}

.tw-relative {
  position: relative
}

.tw-sticky {
  position: -webkit-sticky;
  position: sticky
}

.-tw-top-6 {
  top: -1.5rem
}

.tw-left-0 {
  left: 0px
}

.tw-left-\[-2px\] {
  left: -2px
}

.tw-right-1 {
  right: 0.25rem
}

.tw-right-3 {
  right: 0.75rem
}

.tw-top-0 {
  top: 0px
}

.tw-top-1 {
  top: 0.25rem
}

.tw-top-\[-12px\] {
  top: -12px
}

.tw-top-\[-2px\] {
  top: -2px
}

.tw-z-10 {
  z-index: 10
}

.tw-z-50 {
  z-index: 50
}

.tw-m-0 {
  margin: 0px
}

.tw-m-2 {
  margin: 0.5rem
}

.tw-m-auto {
  margin: auto
}

.\!tw-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important
}

.tw-mx-\[auto\] {
  margin-left: auto;
  margin-right: auto
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto
}

.tw-my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.tw-my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem
}

.tw-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.tw-my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.tw-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.\!tw-mb-0 {
  margin-bottom: 0px !important
}

.\!tw-mb-\[46px\] {
  margin-bottom: 46px !important
}

.\!tw-mt-0 {
  margin-top: 0px !important
}

.tw-mb-0 {
  margin-bottom: 0px
}

.tw-mb-1 {
  margin-bottom: 0.25rem
}

.tw-mb-1\.5 {
  margin-bottom: 0.375rem
}

.tw-mb-2 {
  margin-bottom: 0.5rem
}

.tw-mb-3 {
  margin-bottom: 0.75rem
}

.tw-mb-4 {
  margin-bottom: 1rem
}

.tw-mb-5 {
  margin-bottom: 1.25rem
}

.tw-mb-6 {
  margin-bottom: 1.5rem
}

.tw-mb-8 {
  margin-bottom: 2rem
}

.tw-mb-\[100px\] {
  margin-bottom: 100px
}

.tw-mb-\[12px\] {
  margin-bottom: 12px
}

.tw-mb-\[16px\] {
  margin-bottom: 16px
}

.tw-mb-\[3px\] {
  margin-bottom: 3px
}

.tw-mb-\[70px\] {
  margin-bottom: 70px
}

.tw-ml-1 {
  margin-left: 0.25rem
}

.tw-ml-10 {
  margin-left: 2.5rem
}

.tw-ml-2 {
  margin-left: 0.5rem
}

.tw-ml-3 {
  margin-left: 0.75rem
}

.tw-ml-6 {
  margin-left: 1.5rem
}

.tw-mr-2 {
  margin-right: 0.5rem
}

.tw-mr-3 {
  margin-right: 0.75rem
}

.tw-mr-4 {
  margin-right: 1rem
}

.tw-mt-0 {
  margin-top: 0px
}

.tw-mt-0\.5 {
  margin-top: 0.125rem
}

.tw-mt-1 {
  margin-top: 0.25rem
}

.tw-mt-10 {
  margin-top: 2.5rem
}

.tw-mt-2 {
  margin-top: 0.5rem
}

.tw-mt-3 {
  margin-top: 0.75rem
}

.tw-mt-4 {
  margin-top: 1rem
}

.tw-mt-5 {
  margin-top: 1.25rem
}

.tw-mt-6 {
  margin-top: 1.5rem
}

.tw-mt-8 {
  margin-top: 2rem
}

.tw-mt-9 {
  margin-top: 2.25rem
}

.tw-mt-\[-2px\] {
  margin-top: -2px
}

.tw-mt-\[36px\] {
  margin-top: 36px
}

.tw-mt-\[3px\] {
  margin-top: 3px
}

.tw-mt-\[44px\] {
  margin-top: 44px
}

.\!tw-block {
  display: block !important
}

.tw-block {
  display: block
}

.tw-inline-block {
  display: inline-block
}

.tw-inline {
  display: inline
}

.\!tw-flex {
  display: flex !important
}

.tw-flex {
  display: flex
}

.tw-inline-flex {
  display: inline-flex
}

.\!tw-inline-table {
  display: inline-table !important
}

.tw-grid {
  display: grid
}

.tw-hidden {
  display: none
}

.tw-aspect-square {
  aspect-ratio: 1 / 1
}

.\!tw-h-10 {
  height: 2.5rem !important
}

.\!tw-h-12 {
  height: 3rem !important
}

.\!tw-h-4 {
  height: 1rem !important
}

.\!tw-h-5 {
  height: 1.25rem !important
}

.\!tw-h-\[32px\] {
  height: 32px !important
}

.\!tw-h-\[40px\] {
  height: 40px !important
}

.\!tw-h-full {
  height: 100% !important
}

.tw-h-16 {
  height: 4rem
}

.tw-h-2 {
  height: 0.5rem
}

.tw-h-2\.5 {
  height: 0.625rem
}

.tw-h-4 {
  height: 1rem
}

.tw-h-5 {
  height: 1.25rem
}

.tw-h-6 {
  height: 1.5rem
}

.tw-h-8 {
  height: 2rem
}

.tw-h-\[100px\] {
  height: 100px
}

.tw-h-\[150px\] {
  height: 150px
}

.tw-h-\[16px\] {
  height: 16px
}

.tw-h-\[20px\] {
  height: 20px
}

.tw-h-\[254px\] {
  height: 254px
}

.tw-h-\[260px\] {
  height: 260px
}

.tw-h-\[290px\] {
  height: 290px
}

.tw-h-\[30vh\] {
  height: 30vh
}

.tw-h-\[310px\] {
  height: 310px
}

.tw-h-\[40vh\] {
  height: 40vh
}

.tw-h-\[57vh\] {
  height: 57vh
}

.tw-h-\[64px\] {
  height: 64px
}

.tw-h-\[73vh\] {
  height: 73vh
}

.tw-h-\[76px\] {
  height: 76px
}

.tw-h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content
}

.tw-h-full {
  height: 100%
}

.\!tw-max-h-\[32px\] {
  max-height: 32px !important
}

.tw-max-h-\[188px\] {
  max-height: 188px
}

.tw-max-h-\[218px\] {
  max-height: 218px
}

.tw-max-h-\[300px\] {
  max-height: 300px
}

.tw-max-h-\[40px\] {
  max-height: 40px
}

.tw-max-h-\[450px\] {
  max-height: 450px
}

.tw-max-h-\[500px\] {
  max-height: 500px
}

.tw-max-h-\[50dvh\] {
  max-height: 50dvh
}

.tw-max-h-\[580px\] {
  max-height: 580px
}

.tw-min-h-10 {
  min-height: 2.5rem
}

.tw-min-h-24 {
  min-height: 6rem
}

.tw-min-h-4 {
  min-height: 1rem
}

.tw-min-h-\[130px\] {
  min-height: 130px
}

.tw-min-h-\[150px\] {
  min-height: 150px
}

.tw-min-h-\[180px\] {
  min-height: 180px
}

.tw-min-h-\[200px\] {
  min-height: 200px
}

.tw-min-h-\[26px\] {
  min-height: 26px
}

.\!tw-w-10 {
  width: 2.5rem !important
}

.\!tw-w-12 {
  width: 3rem !important
}

.\!tw-w-\[32px\] {
  width: 32px !important
}

.tw-w-1\/4 {
  width: 25%
}

.tw-w-1\/5 {
  width: 20%
}

.tw-w-2 {
  width: 0.5rem
}

.tw-w-2\.5 {
  width: 0.625rem
}

.tw-w-2\/5 {
  width: 40%
}

.tw-w-3\/4 {
  width: 75%
}

.tw-w-3\/5 {
  width: 60%
}

.tw-w-4 {
  width: 1rem
}

.tw-w-5 {
  width: 1.25rem
}

.tw-w-6 {
  width: 1.5rem
}

.tw-w-8 {
  width: 2rem
}

.tw-w-\[144px\] {
  width: 144px
}

.tw-w-\[166px\] {
  width: 166px
}

.tw-w-\[176px\] {
  width: 176px
}

.tw-w-\[210px\] {
  width: 210px
}

.tw-w-\[220px\] {
  width: 220px
}

.tw-w-\[249px\] {
  width: 249px
}

.tw-w-\[30px\] {
  width: 30px
}

.tw-w-\[340px\] {
  width: 340px
}

.tw-w-\[40px\] {
  width: 40px
}

.tw-w-\[450px\] {
  width: 450px
}

.tw-w-\[500px\] {
  width: 500px
}

.tw-w-\[64px\] {
  width: 64px
}

.tw-w-\[672px\] {
  width: 672px
}

.tw-w-\[680px\] {
  width: 680px
}

.tw-w-\[702px\] {
  width: 702px
}

.tw-w-\[76px\] {
  width: 76px
}

.tw-w-auto {
  width: auto
}

.tw-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.tw-w-full {
  width: 100%
}

.\!tw-min-w-\[150px\] {
  min-width: 150px !important
}

.tw-min-w-4 {
  min-width: 1rem
}

.tw-min-w-\[117px\] {
  min-width: 117px
}

.tw-min-w-\[120px\] {
  min-width: 120px
}

.tw-min-w-\[136px\] {
  min-width: 136px
}

.tw-min-w-\[150px\] {
  min-width: 150px
}

.tw-min-w-\[176px\] {
  min-width: 176px
}

.tw-min-w-\[180px\] {
  min-width: 180px
}

.tw-min-w-\[200px\] {
  min-width: 200px
}

.tw-min-w-\[236px\] {
  min-width: 236px
}

.tw-min-w-\[24px\] {
  min-width: 24px
}

.tw-min-w-\[250px\] {
  min-width: 250px
}

.tw-min-w-\[385px\] {
  min-width: 385px
}

.tw-min-w-\[70px\] {
  min-width: 70px
}

.\!tw-max-w-\[32px\] {
  max-width: 32px !important
}

.tw-max-w-4 {
  max-width: 1rem
}

.tw-max-w-\[130px\] {
  max-width: 130px
}

.tw-max-w-\[145px\] {
  max-width: 145px
}

.tw-max-w-\[150px\] {
  max-width: 150px
}

.tw-max-w-\[210px\] {
  max-width: 210px
}

.tw-max-w-\[230px\] {
  max-width: 230px
}

.tw-max-w-\[260px\] {
  max-width: 260px
}

.tw-max-w-\[288px\] {
  max-width: 288px
}

.tw-max-w-\[290px\] {
  max-width: 290px
}

.tw-max-w-\[450px\] {
  max-width: 450px
}

.tw-max-w-\[458px\] {
  max-width: 458px
}

.tw-max-w-\[468px\] {
  max-width: 468px
}

.tw-max-w-\[500px\] {
  max-width: 500px
}

.tw-max-w-\[520px\] {
  max-width: 520px
}

.tw-max-w-\[618px\] {
  max-width: 618px
}

.tw-max-w-\[680px\] {
  max-width: 680px
}

.tw-max-w-\[712px\] {
  max-width: 712px
}

.tw-max-w-full {
  max-width: 100%
}

.tw-flex-1 {
  flex: 1 1 0%
}

.tw-flex-auto {
  flex: 1 1 auto
}

.tw-flex-none {
  flex: none
}

.tw-flex-grow {
  flex-grow: 1
}

.tw-cursor-pointer {
  cursor: pointer
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.tw-flex-row {
  flex-direction: row
}

.tw-flex-row-reverse {
  flex-direction: row-reverse
}

.tw-flex-col {
  flex-direction: column
}

.tw-flex-wrap {
  flex-wrap: wrap
}

.tw-place-items-center {
  place-items: center
}

.\!tw-items-start {
  align-items: flex-start !important
}

.tw-items-start {
  align-items: flex-start
}

.\!tw-items-end {
  align-items: flex-end !important
}

.tw-items-end {
  align-items: flex-end
}

.\!tw-items-center {
  align-items: center !important
}

.tw-items-center {
  align-items: center
}

.tw-justify-start {
  justify-content: flex-start
}

.tw-justify-end {
  justify-content: flex-end
}

.tw-justify-center {
  justify-content: center
}

.tw-justify-between {
  justify-content: space-between
}

.\!tw-gap-1 {
  gap: 0.25rem !important
}

.tw-gap-0 {
  gap: 0px
}

.tw-gap-0\.5 {
  gap: 0.125rem
}

.tw-gap-1 {
  gap: 0.25rem
}

.tw-gap-1\.5 {
  gap: 0.375rem
}

.tw-gap-12 {
  gap: 3rem
}

.tw-gap-2 {
  gap: 0.5rem
}

.tw-gap-2\.5 {
  gap: 0.625rem
}

.tw-gap-3 {
  gap: 0.75rem
}

.tw-gap-4 {
  gap: 1rem
}

.tw-gap-5 {
  gap: 1.25rem
}

.tw-gap-6 {
  gap: 1.5rem
}

.tw-gap-8 {
  gap: 2rem
}

.tw-gap-9 {
  gap: 2.25rem
}

.tw-gap-\[2px\] {
  gap: 2px
}

.tw-gap-\[5px\] {
  gap: 5px
}

.tw-gap-x-0 {
  column-gap: 0px
}

.tw-gap-x-0\.5 {
  column-gap: 0.125rem
}

.tw-gap-x-1 {
  column-gap: 0.25rem
}

.tw-gap-x-2 {
  column-gap: 0.5rem
}

.tw-gap-y-0 {
  row-gap: 0px
}

.tw-gap-y-0\.5 {
  row-gap: 0.125rem
}

.tw-gap-y-2 {
  row-gap: 0.5rem
}

.tw-gap-y-3 {
  row-gap: 0.75rem
}

.tw-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
}

.tw-self-end {
  align-self: flex-end
}

.\!tw-self-center {
  align-self: center !important
}

.tw-overflow-auto {
  overflow: auto
}

.tw-overflow-hidden {
  overflow: hidden
}

.tw-overflow-y-auto {
  overflow-y: auto
}

.tw-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tw-text-ellipsis {
  text-overflow: ellipsis
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap
}

.\!tw-rounded {
  border-radius: 0.25rem !important
}

.tw-rounded {
  border-radius: 0.25rem
}

.tw-rounded-2xl {
  border-radius: 1rem
}

.tw-rounded-\[100px\] {
  border-radius: 100px
}

.tw-rounded-\[10px\] {
  border-radius: 10px
}

.tw-rounded-\[17px\] {
  border-radius: 17px
}

.tw-rounded-\[50\%\] {
  border-radius: 50%
}

.tw-rounded-full {
  border-radius: 9999px
}

.tw-rounded-lg {
  border-radius: 0.5rem
}

.tw-rounded-none {
  border-radius: 0px
}

.tw-rounded-sm {
  border-radius: 0.125rem
}

.tw-rounded-xl {
  border-radius: 0.75rem
}

.tw-border {
  border-width: 1px
}

.\!tw-border-b-0 {
  border-bottom-width: 0px !important
}

.\!tw-border-l-0 {
  border-left-width: 0px !important
}

.\!tw-border-r-0 {
  border-right-width: 0px !important
}

.tw-border-b {
  border-bottom-width: 1px
}

.tw-border-l-0 {
  border-left-width: 0px
}

.tw-border-r-0 {
  border-right-width: 0px
}

.tw-border-t-0 {
  border-top-width: 0px
}

.tw-border-solid {
  border-style: solid
}

.\!tw-border-none {
  border-style: none !important
}

.tw-border-none {
  border-style: none
}

.\!tw-border-error-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(177 57 57 / var(--tw-border-opacity)) !important
}

.\!tw-border-grey-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(196 196 196 / var(--tw-border-opacity)) !important
}

.tw-border-error-500 {
  --tw-border-opacity: 1;
  border-color: rgb(177 57 57 / var(--tw-border-opacity))
}

.tw-border-error-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 39 39 / var(--tw-border-opacity))
}

.tw-border-grey-300 {
  --tw-border-opacity: 1;
  border-color: rgb(218 217 217 / var(--tw-border-opacity))
}

.tw-border-grey-500 {
  --tw-border-opacity: 1;
  border-color: rgb(196 196 196 / var(--tw-border-opacity))
}

.tw-border-grey-700 {
  --tw-border-opacity: 1;
  border-color: rgb(137 137 137 / var(--tw-border-opacity))
}

.tw-border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 170 75 / var(--tw-border-opacity))
}

.tw-border-primary-800 {
  --tw-border-opacity: 1;
  border-color: rgb(67 113 50 / var(--tw-border-opacity))
}

.tw-border-success-primary {
  --tw-border-opacity: 1;
  border-color: rgb(100 170 75 / var(--tw-border-opacity))
}

.\!tw-bg-primary-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 238 219 / var(--tw-bg-opacity)) !important
}

.\!tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.tw-bg-error-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(177 57 57 / var(--tw-bg-opacity))
}

.tw-bg-grey-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity))
}

.tw-bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity))
}

.tw-bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(218 217 217 / var(--tw-bg-opacity))
}

.tw-bg-grey-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 227 / var(--tw-bg-opacity))
}

.tw-bg-grey-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity))
}

.tw-bg-grey-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 196 196 / var(--tw-bg-opacity))
}

.tw-bg-grey-light {
  --tw-bg-opacity: 1;
  background-color: rgb(201 201 201 / var(--tw-bg-opacity))
}

.tw-bg-primary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 196 130 / var(--tw-bg-opacity))
}

.tw-bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 238 219 / var(--tw-bg-opacity))
}

.tw-bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 170 75 / var(--tw-bg-opacity))
}

.tw-bg-primary-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 113 50 / var(--tw-bg-opacity))
}

.tw-bg-secondary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(173 231 255 / var(--tw-bg-opacity))
}

.tw-bg-secondary-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(58 165 218 / var(--tw-bg-opacity))
}

.tw-bg-secondary-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 83 141 / var(--tw-bg-opacity))
}

.tw-bg-secondary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 68 116 / var(--tw-bg-opacity))
}

.tw-bg-warning-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 213 191 / var(--tw-bg-opacity))
}

.tw-bg-warning-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 115 41 / var(--tw-bg-opacity))
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.\!tw-p-0 {
  padding: 0px !important
}

.\!tw-p-2 {
  padding: 0.5rem !important
}

.tw-p-0 {
  padding: 0px
}

.tw-p-2 {
  padding: 0.5rem
}

.tw-p-3 {
  padding: 0.75rem
}

.tw-p-4 {
  padding: 1rem
}

.tw-p-5 {
  padding: 1.25rem
}

.tw-p-6 {
  padding: 1.5rem
}

.tw-p-8 {
  padding: 2rem
}

.tw-p-\[11px\] {
  padding: 11px
}

.tw-p-\[20px\] {
  padding: 20px
}

.tw-p-\[8px\] {
  padding: 8px
}

.\!tw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important
}

.\!tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.\!tw-py-\[5px\] {
  padding-top: 5px !important;
  padding-bottom: 5px !important
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.tw-py-0 {
  padding-top: 0px;
  padding-bottom: 0px
}

.tw-py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.tw-py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px
}

.tw-pb-0 {
  padding-bottom: 0px
}

.tw-pb-3 {
  padding-bottom: 0.75rem
}

.tw-pb-4 {
  padding-bottom: 1rem
}

.tw-pl-3 {
  padding-left: 0.75rem
}

.tw-pl-5 {
  padding-left: 1.25rem
}

.tw-pl-6 {
  padding-left: 1.5rem
}

.tw-pl-\[20px\] {
  padding-left: 20px
}

.tw-pl-\[24px\] {
  padding-left: 24px
}

.tw-pl-\[25px\] {
  padding-left: 25px
}

.tw-pr-3 {
  padding-right: 0.75rem
}

.tw-pr-4 {
  padding-right: 1rem
}

.tw-pt-2 {
  padding-top: 0.5rem
}

.tw-pt-3 {
  padding-top: 0.75rem
}

.tw-pt-\[22px\] {
  padding-top: 22px
}

.tw-text-left {
  text-align: left
}

.tw-text-center {
  text-align: center
}

.tw-text-right {
  text-align: right
}

.tw-font-oxygen {
  font-family: Oxygen, sans-serif
}

.tw-font-poppins {
  font-family: Poppins, sans-serif
}

.tw-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.tw-text-\[10px\] {
  font-size: 10px
}

.tw-text-\[12px\] {
  font-size: 12px
}

.tw-text-\[14px\] {
  font-size: 14px
}

.tw-text-\[16px\] {
  font-size: 16px
}

.tw-text-\[20px\] {
  font-size: 20px
}

.tw-text-\[24px\] {
  font-size: 24px
}

.tw-text-\[34px\] {
  font-size: 34px
}

.tw-text-\[42px\] {
  font-size: 42px
}

.tw-text-\[50px\] {
  font-size: 50px
}

.tw-text-\[64px\] {
  font-size: 64px
}

.tw-text-\[6px\] {
  font-size: 6px
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.\!tw-font-normal {
  font-weight: 400 !important
}

.tw-font-bold {
  font-weight: 700
}

.tw-font-medium {
  font-weight: 500
}

.tw-font-normal {
  font-weight: 400
}

.tw-font-semibold {
  font-weight: 600
}

.tw-uppercase {
  text-transform: uppercase
}

.tw-capitalize {
  text-transform: capitalize
}

.\!tw-leading-5 {
  line-height: 1.25rem !important
}

.\!tw-leading-\[1px\] {
  line-height: 1px !important
}

.tw-leading-4 {
  line-height: 1rem
}

.tw-leading-5 {
  line-height: 1.25rem
}

.tw-leading-\[14px\] {
  line-height: 14px
}

.tw-leading-\[16px\] {
  line-height: 16px
}

.tw-leading-\[18px\] {
  line-height: 18px
}

.tw-leading-\[1px\] {
  line-height: 1px
}

.tw-leading-\[20px\] {
  line-height: 20px
}

.tw-leading-\[23px\] {
  line-height: 23px
}

.tw-leading-\[24px\] {
  line-height: 24px
}

.tw-leading-\[28px\] {
  line-height: 28px
}

.tw-leading-\[32px\] {
  line-height: 32px
}

.tw-leading-\[42px\] {
  line-height: 42px
}

.tw-leading-\[4px\] {
  line-height: 4px
}

.tw-leading-\[56px\] {
  line-height: 56px
}

.tw-leading-\[64px\] {
  line-height: 64px
}

.tw-leading-\[72px\] {
  line-height: 72px
}

.tw-leading-none {
  line-height: 1
}

.\!tw-text-error-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(177 57 57 / var(--tw-text-opacity)) !important
}

.\!tw-text-grey-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 75 75 / var(--tw-text-opacity)) !important
}

.\!tw-text-grey-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(25 25 25 / var(--tw-text-opacity)) !important
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.tw-text-error-500 {
  --tw-text-opacity: 1;
  color: rgb(177 57 57 / var(--tw-text-opacity))
}

.tw-text-error-600 {
  --tw-text-opacity: 1;
  color: rgb(147 39 39 / var(--tw-text-opacity))
}

.tw-text-grey-600 {
  --tw-text-opacity: 1;
  color: rgb(150 150 150 / var(--tw-text-opacity))
}

.tw-text-grey-700 {
  --tw-text-opacity: 1;
  color: rgb(137 137 137 / var(--tw-text-opacity))
}

.tw-text-grey-800 {
  --tw-text-opacity: 1;
  color: rgb(75 75 75 / var(--tw-text-opacity))
}

.tw-text-grey-900 {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity))
}

.tw-text-info-500 {
  --tw-text-opacity: 1;
  color: rgb(63 121 144 / var(--tw-text-opacity))
}

.tw-text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(100 170 75 / var(--tw-text-opacity))
}

.tw-text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(93 163 68 / var(--tw-text-opacity))
}

.tw-text-primary-800 {
  --tw-text-opacity: 1;
  color: rgb(67 113 50 / var(--tw-text-opacity))
}

.tw-text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(50 85 38 / var(--tw-text-opacity))
}

.tw-text-secondary-500 {
  --tw-text-opacity: 1;
  color: rgb(4 131 195 / var(--tw-text-opacity))
}

.tw-text-success-500 {
  --tw-text-opacity: 1;
  color: rgb(10 122 8 / var(--tw-text-opacity))
}

.tw-text-warning-300 {
  --tw-text-opacity: 1;
  color: rgb(194 115 41 / var(--tw-text-opacity))
}

.tw-text-warning-500 {
  --tw-text-opacity: 1;
  color: rgb(242 157 65 / var(--tw-text-opacity))
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.tw-underline {
  text-decoration-line: underline
}

.tw-no-underline {
  text-decoration-line: none
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-shadow-primary {
  --tw-shadow: 0px 0px 10px 0px rgba(130, 134, 139, 0.20);
  --tw-shadow-colored: 0px 0px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tw-shadow-simple {
  --tw-shadow: 0px 0px 5px 0px rgba(130, 134, 139, 0.30);
  --tw-shadow-colored: 0px 0px 5px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.\!tw-border-primary {
  border: 1px solid #DAD9D9 !important
}

.tw-border-primary {
  border: 1px solid #DAD9D9
}

.after\:tw-absolute::after {
  content: var(--tw-content);
  position: absolute
}

.after\:tw--bottom-3::after {
  content: var(--tw-content);
  bottom: -0.75rem
}

.after\:tw-left-0::after {
  content: var(--tw-content);
  left: 0px
}

.after\:tw-w-full::after {
  content: var(--tw-content);
  width: 100%
}

.after\:tw-border-b-2::after {
  content: var(--tw-content);
  border-bottom-width: 2px
}

.after\:tw-border-solid::after {
  content: var(--tw-content);
  border-style: solid
}

.after\:tw-border-\[\#437132\]::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(67 113 50 / var(--tw-border-opacity))
}

.after\:tw-border-primary-800::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(67 113 50 / var(--tw-border-opacity))
}

.after\:tw-content-\[\'\'\]::after {
  --tw-content: '';
  content: var(--tw-content)
}

.last\:tw-mb-0:last-child {
  margin-bottom: 0px
}

.hover\:tw-cursor-pointer:hover {
  cursor: pointer
}

.hover\:\!tw-border-none:hover {
  border-style: none !important
}

.hover\:\!tw-border-error-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(177 57 57 / var(--tw-border-opacity)) !important
}

.hover\:\!tw-border-grey-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(196 196 196 / var(--tw-border-opacity)) !important
}

.hover\:tw-border-error-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(177 57 57 / var(--tw-border-opacity))
}

.hover\:tw-border-grey-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(196 196 196 / var(--tw-border-opacity))
}

.hover\:\!tw-bg-error-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(177 57 57 / var(--tw-bg-opacity)) !important
}

.hover\:\!tw-bg-primary-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 238 219 / var(--tw-bg-opacity)) !important
}

.hover\:\!tw-bg-primary-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(100 170 75 / var(--tw-bg-opacity)) !important
}

.hover\:\!tw-bg-primary-800:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(67 113 50 / var(--tw-bg-opacity)) !important
}

.hover\:\!tw-bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.hover\:tw-bg-grey-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 227 / var(--tw-bg-opacity))
}

.hover\:tw-bg-primary-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 238 219 / var(--tw-bg-opacity))
}

.hover\:tw-bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.hover\:tw-font-semibold:hover {
  font-weight: 600
}

.hover\:\!tw-text-error-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(177 57 57 / var(--tw-text-opacity)) !important
}

.hover\:\!tw-text-grey-900:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(25 25 25 / var(--tw-text-opacity)) !important
}

.hover\:\!tw-text-info-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(63 121 144 / var(--tw-text-opacity)) !important
}

.hover\:\!tw-text-primary-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(148 196 130 / var(--tw-text-opacity)) !important
}

.hover\:\!tw-text-primary-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(100 170 75 / var(--tw-text-opacity)) !important
}

.hover\:\!tw-text-primary-800:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(67 113 50 / var(--tw-text-opacity)) !important
}

.hover\:\!tw-text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.hover\:tw-text-error-600:hover {
  --tw-text-opacity: 1;
  color: rgb(147 39 39 / var(--tw-text-opacity))
}

.hover\:tw-text-grey-700:hover {
  --tw-text-opacity: 1;
  color: rgb(137 137 137 / var(--tw-text-opacity))
}

.hover\:tw-text-grey-800:hover {
  --tw-text-opacity: 1;
  color: rgb(75 75 75 / var(--tw-text-opacity))
}

.hover\:tw-text-grey-900:hover {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity))
}

.hover\:tw-text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:tw-underline:hover {
  text-decoration-line: underline
}

.hover\:tw-opacity-100:hover {
  opacity: 1
}

.hover\:tw-opacity-60:hover {
  opacity: 0.6
}

.hover\:tw-opacity-75:hover {
  opacity: 0.75
}

.hover\:tw-opacity-\[1\]:hover {
  opacity: 1
}

.hover\:tw-shadow-simple:hover {
  --tw-shadow: 0px 0px 5px 0px rgba(130, 134, 139, 0.30);
  --tw-shadow-colored: 0px 0px 5px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:\!tw-border-primary:hover {
  border: 1px solid #DAD9D9 !important
}

.focus\:\!tw-border-0:focus {
  border-width: 0px !important
}

.focus\:\!tw-border-none:focus {
  border-style: none !important
}

.focus\:\!tw-bg-white:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.disabled\:tw-bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.disabled\:tw-text-grey-900:disabled {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity))
}

.disabled\:hover\:\!tw-bg-grey-50:hover:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity)) !important
}

.disabled\:hover\:\!tw-text-grey-500:hover:disabled {
  --tw-text-opacity: 1 !important;
  color: rgb(196 196 196 / var(--tw-text-opacity)) !important
}

@media (max-width: 479px) {
  .xs\:tw-ml-0 {
    margin-left: 0px
  }

  .xs\:tw-mt-3 {
    margin-top: 0.75rem
  }

  .xs\:tw-mt-4 {
    margin-top: 1rem
  }

  .xs\:tw-h-\[48px\] {
    height: 48px
  }

  .xs\:tw-w-\[48px\] {
    width: 48px
  }

  .xs\:tw-w-full {
    width: 100%
  }

  .xs\:tw-max-w-\[150px\] {
    max-width: 150px
  }

  .xs\:tw-max-w-\[195px\] {
    max-width: 195px
  }

  .xs\:tw-flex-1 {
    flex: 1 1 0%
  }

  .xs\:tw-flex-col {
    flex-direction: column
  }

  .xs\:tw-items-start {
    align-items: flex-start
  }

  .xs\:tw-justify-end {
    justify-content: flex-end
  }

  .xs\:tw-justify-center {
    justify-content: center
  }

  .xs\:tw-gap-0 {
    gap: 0px
  }

  .xs\:tw-gap-1 {
    gap: 0.25rem
  }

  .xs\:tw-gap-3 {
    gap: 0.75rem
  }

  .xs\:tw-px-\[8px\] {
    padding-left: 8px;
    padding-right: 8px
  }

  .xs\:tw-text-\[14px\] {
    font-size: 14px
  }

  .xs\:tw-leading-5 {
    line-height: 1.25rem
  }
}

@media (max-width: 767px) {
  .sm\:tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
  }

  .sm\:tw-mb-4 {
    margin-bottom: 1rem
  }

  .sm\:tw-mt-4 {
    margin-top: 1rem
  }

  .sm\:tw-w-full {
    width: 100%
  }

  .sm\:tw-max-w-full {
    max-width: 100%
  }

  .sm\:tw-flex-1 {
    flex: 1 1 0%
  }

  .sm\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .sm\:tw-flex-col {
    flex-direction: column
  }

  .sm\:\!tw-items-start {
    align-items: flex-start !important
  }

  .sm\:tw-justify-center {
    justify-content: center
  }

  .sm\:tw-gap-1 {
    gap: 0.25rem
  }

  .sm\:tw-gap-3 {
    gap: 0.75rem
  }

  .sm\:tw-rounded-none {
    border-radius: 0px
  }

  .sm\:tw-p-3 {
    padding: 0.75rem
  }

  .sm\:tw-p-4 {
    padding: 1rem
  }

  .sm\:tw-px-0 {
    padding-left: 0px;
    padding-right: 0px
  }
}

@media (min-width: 1024px) {
  .lg\:tw-max-w-\[200px\] {
    max-width: 200px
  }
}

.\[\&\.ant-dropdown-open\]\:\!tw-bg-primary-50.ant-dropdown-open {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 238 219 / var(--tw-bg-opacity)) !important
}

.\[\&\>div\>div\>div\>div\>div\]\:tw-w-full>div>div>div>div>div {
  width: 100%
}

.\[\&\>span\+span\]\:tw-inline-flex>span+span {
  display: inline-flex
}
