.shared-success-submitted-item {
  @apply tw-block tw-shadow-sm tw-border-grey-500 tw-rounded-lg tw-border-solid tw-border tw-p-3 tw-bg-white tw-cursor-pointer hover:tw-opacity-70;
  .wrap {
    @apply tw-text-start tw-flex tw-justify-between tw-items-center tw-h-59;

    p {
      @apply tw-m-0 tw-text-grey-900;
    }
  }

  &.horizontal {
    @apply tw-flex-1;
    .wrap {
      @apply tw-flex-col tw-h-auto tw-items-start;

      p {
        @apply tw-mb-2;
      }
    }
  }
}
